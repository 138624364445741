<template>
  <div class="home">
      <div class="homeComAbout">
<!--          <h1 class="homeTitle center">-->
<!--              <img style="width: 60px;height: 60px;" src="@/assets/logo5.png" alt="">-->
<!--            贵州浩佑暖通制冷设备有限公司-->
<!--          </h1>-->
          <div class="banner-img center">
              <img src="@/assets/content.png" alt="" style="height: 600px;width: calc(100% - 200px);">
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'homePage',
  components: {
  }
}
</script>

<style lang="scss" scoped="scoped">
  .home{
    background:#fff;
  }
  .homeComAbout{
  .homeTitle{
      font-weight: 700;
      color: #303133;
      font-size: 2rem;
  }
  .titleIntroduce{
      color: #606266;
      font-size:1.1rem ;
      padding-bottom:14px;
  }
  .center{
      text-align: center;
  }
  }
</style>
